import React from "react"
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const M = typeof window !== `undefined` ? require("materialize-css") : null

class Layout extends React.Component {
  componentDidMount() {
    setTimeout(function () {
      const mobileNavElems = document.querySelectorAll(".sidenav")
      const mobileNavInstances = M.Sidenav.init(mobileNavElems, {
        preventScrolling: true,
        draggable: false,
      })
    }, 500)
  }
  render() {
    let activePage = this.props.children[0].props.title;
    return (
      <>
        <Helmet>
          {/* Materialize Icons */}
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />
          {/* Font Awesome */}
          <script src="https://use.fontawesome.com/b78db76168.js"></script>
        </Helmet>
        <Header activePage={activePage} />
        <main>{this.props.children}</main>
        <Footer />
      </>
    )
  }
}

export default Layout
