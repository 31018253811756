import React from "react"
import { Link } from "gatsby"
import NavLogo from "../components/nav-logo"

class Header extends React.Component {

  render() {
    let activeNav = this.props.activePage

    return (
      <header>
        {/* Sidenav Structure */}
        <ul className="sidenav" id="mobile-demo">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            {activeNav === "About" ? (
              <Link to="/about" className="sidenav-close active-nav">
                About
              </Link>
            ) : (
              <Link to="/about" className="sidenav-close">
                About
              </Link>
            )}
          </li>
          <li>
            {activeNav === "Film/TV" ? (
              <Link to="/film-tv" className="sidenav-close active-nav">
                Film/TV
              </Link>
            ) : (
              <Link to="/film-tv" className="sidenav-close">
                Film/TV
              </Link>
            )}
          </li>
          <li>
            {activeNav === "Music" ? (
              <Link to="/music" className="sidenav-close active-nav">
                Music
              </Link>
            ) : (
              <Link to="/music" className="sidenav-close">
                Music
              </Link>
            )}
          </li>
          <li>
            {activeNav === "Development" ? (
              <Link to="/development" className="sidenav-close active-nav">
                Development
              </Link>
            ) : (
              <Link to="/development" className="sidenav-close">
                Development
              </Link>
            )}
          </li>
          <li>
            {activeNav === "Press" ? (
              <Link to="/press" className="sidenav-close active-nav">
                Press
              </Link>
            ) : (
              <Link to="/press" className="sidenav-close">
                Press
              </Link>
            )}
          </li>
          <li>
            {activeNav === "Contact" ? (
              <Link to="/contact" className="sidenav-close active-nav">
                Contact
              </Link>
            ) : (
              <Link to="/contact" className="sidenav-close">
                Contact
              </Link>
            )}
          </li>
        </ul>
        <nav>
          <div className="container">
            <div className="nav-wrapper">
              <Link to="/" className="brand-logo">
                <NavLogo />
              </Link>
              <a href="#" data-target="mobile-demo" className="sidenav-trigger">
                <i className="material-icons">menu</i>
              </a>
              <ul id="nav-mobile" className="right hide-on-med-and-down">
                <li>
                  {activeNav === "Home" ? (
                    <Link to="/" className="active-nav">
                      Home
                    </Link>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                <li>
                  {activeNav === "About" ? (
                    <Link to="/about" className="active-nav">
                      About
                    </Link>
                  ) : (
                    <Link to="/about">About</Link>
                  )}
                </li>
                <li>
                  {activeNav === "Film/TV" ? (
                    <Link to="/film-tv" className="active-nav">
                      Film/TV
                    </Link>
                  ) : (
                    <Link to="/film-tv">Film/TV</Link>
                  )}
                </li>
                <li>
                  {activeNav === "Music" ? (
                    <Link to="/music" className="active-nav">
                      Music
                    </Link>
                  ) : (
                    <Link to="/music">Music</Link>
                  )}
                </li>
                <li>
                  {activeNav === "Development" ? (
                    <Link to="/development" className="active-nav">
                      Development
                    </Link>
                  ) : (
                    <Link to="/development">Development</Link>
                  )}
                </li>
                <li>
                  {activeNav === "Press" ? (
                    <Link to="/press" className="active-nav">
                      Press
                    </Link>
                  ) : (
                    <Link to="/press">Press</Link>
                  )}
                </li>
                <li>
                  {activeNav === "Contact" ? (
                    <Link to="/contact" className="active-nav">
                      Contact
                    </Link>
                  ) : (
                    <Link to="/contact">Contact</Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
