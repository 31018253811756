import React from "react"

const Footer = () => (
  <footer className="page-footer">
    <div className="footer-copyright">
      <div className="container">
        <div className="row">
          <div className="col s12 center-align icons">
            <a
              href="https://www.facebook.com/IMPRINT-ENTERTAINMENT-120818451267628/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
            </a>
            <a
              href="https://twitter.com/ImprintEnt"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-twitter-square fa-2x" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.imdb.com/search/title/?companies=co0247764"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-imdb fa-2x" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col s12 center-align">
            <p className="copyright">
              © {new Date().getUTCFullYear()} Imprint Entertainment
            </p>
            <a
              href="https://www.holisticwebsolutions.com/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="hws">Created by Holistic Web Solutions</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
