import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const NavLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "IE_logo_transparent.png" }) {
        childImageSharp {
          fixed(height: 50, width: 132, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      className="nav-img"
      fixed={data.placeholderImage.childImageSharp.fixed}
      alt="Imprint Entertainment"
      loading="eager"
    />
  )
}

export default NavLogo